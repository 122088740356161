<template>
  <div v-if="blogs.length">
    <div class="sector__blog mb-40" v-for="(ele, index) in blogs" :key="index">
      <h3 class="mb-16">{{ ele.title }}</h3>
      <div class="sector__card">
        <img
          :src="ele.banner_img"
          alt=""
          width="790"
          height="236"
          class="mb-24"
        />
        <div class="blog__content" v-html="ele.content"></div>
        <a href="#" class="flex more" title="थप पढ्नुहोस्"
          >थप पढ्नुहोस्<i class="arrow-right"></i
        ></a>
      </div>
    </div>
  </div>
  <NoDataFound v-else></NoDataFound>
</template>

<script>
import BlogRepository from "./../../repositories/BlogRepository";
import NoDataFound from "./../NoDataFound.vue";
export default {
  data() {
    return {
      blogs: [],
      sector_types: {
        health: "स्वास्थ्य",
        education: "शिक्षा",
        agriculture: "कृषि",
        water_sanitation: "पानी र सरसफाइ",
      },
    };
  },
  components: {
    NoDataFound,
  },
  mounted() {
    this.getBlogs();
  },
  methods: {
    async getBlogs() {
      let { data } = await BlogRepository.getBlogs(
        this.sector_types[this.$route.params.type]
      );

      if (data.status) {
        this.blogs = data.data;
      }

      this.$store.dispatch("setLoading", false);
    },
  },
};
</script>

<style lang="scss">
.sector__card {
  > img {
    border-radius: 16px;
    object-fit: cover;
    width: 100%;
  }
}

.blog__content {
  img {
    max-width: 100%;
  }
  p {
    overflow: hidden;
  }
  ul li,
  p {
    font-family: "Mukta-Medium";
    margin-bottom: 24px;
  }
  img[style="float:left"],
  img[style="float:right"] {
    max-width: 50%;
  }
}
</style>
